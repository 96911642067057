<template>
  <div
    v-if="requestHisory"
    class="row"
  >
    <div class="col-md-8">
      <b-card>
        <div class="row">
          <div class="col-md-6">
            <dl class="mb-0">
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Booking ID') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ requestHisory.store_order_invoice_id }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('User Name') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ requestHisory.user ? `${requestHisory.user.first_name} ${requestHisory.user.last_name}` : 'N/A' }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Payment Mode') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ invoiceData ? invoiceData.payment_mode : 'NA' }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Total Amount') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ invoiceData ? `${requestHisory.currency}${(invoiceData.total_amount - invoiceData.discount).toFixed(3)}` : 'NA' }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Promo Discount Amount') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ invoiceData ? requestHisory.currency + invoiceData.discount : showNA('') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('User Rating') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.user.rating) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Status') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ requestHisory.status }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Cancel By') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.cancelled_by) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Reason') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.reason ? requestHisory.reason.reason : requestHisory.cancel_reason) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Order Type') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.order_type) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Payment Mode') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.payment_mode) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Created Time') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.created_time) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Accepted at') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(acceptedAt(requestHisory.history)) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Order ready time') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.pickup ? (requestHisory.pickup.prepare_time + ' ' + requestHisory.pickup.prepare_time_type) : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Assigned at') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.assigned_at) }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="col-md-6">
            <dl class="mb-0">
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Agent') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.agent ? requestHisory.agent.name : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Cancelled By') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.cancelled_by ? requestHisory.cancelled_by : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('COD') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.cod != null ? (requestHisory.cod ? 'Yes' : 'No') : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Contactless Delivery') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.contactless_delivery != null ? (requestHisory.contactless_delivery ? 'Yes' : 'No') : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Delivery Rate') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.delivery_date) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Delivery Rated') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.delivery_rated) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Delivery Time') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.delivery_time) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Discount') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(invoiceData ? invoiceData.discount : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Distance') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.distance) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Free Delivery') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.free_delivery != null ? (requestHisory.free_delivery ? 'Yes' : 'No') : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Ignored By') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.ignored_by) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Leave at Door') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.leave_at_door != null ? (requestHisory.leave_at_door ? 'Yes' : 'No') : '') }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('No of Guests') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.number_guests) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Note') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.note) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Schedule Time') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.schedule_datetime) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Schedule Status') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.schedule_status) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Weight') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.weight) }}
                </dd>
              </div>
              <div class="row flex-nowrap">
                <dt class="col-5">
                  {{ $t('Without Item') }}
                </dt>
                <dt class="col-1">
                  :
                </dt>
                <dd class="col-6">
                  {{ showNA(requestHisory.without_item != null ? (requestHisory.without_item ? 'Yes' : 'No') : '') }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </b-card>
      <b-card>
        <dl class="mb-0">
          <div class="row flex-nowrap">
            <dt class="col-12">
              {{ $t('LOCATION') }}
            </dt>
          </div>
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('DELIVERY LOCATION') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ requestHisory.delivery ? requestHisory.delivery.map_address : '' }}
            </dd>
          </div>
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('Store Name') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ requestHisory.pickup ? requestHisory.pickup.store_name : '' }}
            </dd>
          </div>
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('Store Location') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ requestHisory.pickup ? requestHisory.pickup.store_location : '' }}
            </dd>
          </div>
        </dl>
      </b-card>
      <b-card v-if="requestHisory.history && requestHisory.history.length > 0">
        <h4 class="mb-1 pl-0 font-weight-bold">
          <b>{{ $t('History') }}</b>
        </h4>
        <app-timeline>
          <app-timeline-item
            v-for="(item, index) of requestHisory.history"
            :key="index"
            :title="item.status + ' at ' + item.created_at"
            :variant="index == requestHisory.history.length - 1 ? 'secondary' : 'primary'"
          />
        </app-timeline>
      </b-card>
    </div>
    <div
      v-if="invoiceData"
      class="col-md-4"
    >
      <b-card>
        <p>{{ $t('ITEM DETAILS') }}</p>
        <dl
          v-for="(item, index) of invoiceData.items"
          :key="index"
          class="mb-0 border-bottom"
        >
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('Item Name') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ item.product.item_name }}
            </dd>
          </div>
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('Item QTY') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ item.quantity }}
            </dd>
          </div>
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('Item Price') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ item.product.item_price }}
            </dd>
          </div>
          <div class="row flex-nowrap">
            <dt class="col-5">
              {{ $t('Total') }}
            </dt>
            <dt class="col-1">
              :
            </dt>
            <dd class="col-6">
              {{ requestHisory.currency }}{{ item.total_item_price }}
            </dd>
          </div>
        </dl>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    requestHisory: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    invoiceData() {
      if (this.requestHisory && this.requestHisory.invoice) {
        return this.requestHisory.invoice
      }
      if (this.requestHisory && this.requestHisory.order_invoice) {
        return this.requestHisory.order_invoice
      }
      return null
    },
  },
  methods: {
    acceptedAt(history) {
      if (Array.isArray(history)) {
        const isAcceptedExist = history.find(x => x.status === 'ACCEPTED')
        if (isAcceptedExist) {
          return isAcceptedExist.created_at
        }
      }
      return ''
    },
    showNA(value) {
      return value == null || value === '' ? 'N/A' : value
    },
  },
}
</script>
